@font-face {
    font-family: 'Rostelecom Basis';
    src: url('../fonts/RostelecomBasis-Medium.eot');
    src: url('../fonts/RostelecomBasis-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RostelecomBasis-Medium.woff2') format('woff2'),
        url('../fonts/RostelecomBasis-Medium.woff') format('woff'),
        url('../fonts/RostelecomBasis-Medium.ttf') format('truetype'),
        url('../fonts/RostelecomBasis-Medium.svg#RostelecomBasis-Medium') format('svg');
    font-style: normal;
    font-weight: 500
}

@font-face {
    font-family: 'Rostelecom Basis';
    src: url('../fonts/RostelecomBasis-Light.eot');
    src: url('../fonts/RostelecomBasis-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RostelecomBasis-Light.woff2') format('woff2'),
        url('../fonts/RostelecomBasis-Light.woff') format('woff'),
        url('../fonts/RostelecomBasis-Light.ttf') format('truetype'),
        url('../fonts/RostelecomBasis-Light.svg#RostelecomBasis-Light') format('svg');
    font-style: normal;
    font-weight: 300
}

@font-face {
    font-family: 'Rostelecom Basis';
    src: url('../fonts/RostelecomBasis-Regular.eot');
    src: url('../fonts/RostelecomBasis-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RostelecomBasis-Regular.woff2') format('woff2'),
        url('../fonts/RostelecomBasis-Regular.woff') format('woff'),
        url('../fonts/RostelecomBasis-Regular.ttf') format('truetype'),
        url('../fonts/RostelecomBasis-Regular.svg#RostelecomBasis-Regular') format('svg');
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: 'Rostelecom Basis';
    src: url('../fonts/RostelecomBasis-Bold.eot');
    src: url('../fonts/RostelecomBasis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RostelecomBasis-Bold.woff2') format('woff2'),
        url('../fonts/RostelecomBasis-Bold.woff') format('woff'),
        url('../fonts/RostelecomBasis-Bold.ttf') format('truetype'),
        url('../fonts/RostelecomBasis-Bold.svg#RostelecomBasis-Bold') format('svg');
    font-style: normal;
    font-weight: 700
}