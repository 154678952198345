.contracted-budget-table {

  .table-mini-select {
    padding: 6px 2px !important;
  }

  .year-col {
    min-width: 90px;
  }

  .spanned-bottom {
    vertical-align: bottom !important;
    padding-bottom: 10px !important;
    min-width: 88px;
  }
}

.budget-prediction-form {
  .input-title__description {
    padding-left: 0;
  }
}

.prediction-status {
    &.RELEASE {
      color: #00d359;
    }

    &.COORDINATION,
    &.PRELIMINARY_COORDINATION {
      color: #ffb608;
    }

    &.REWORK,
    &.EDITING {
      color: #ff4747;
    }
}

.prediction-table__wrapper {
  margin-top: 10px;
  overflow: auto;
}

.orders-r12-table {
  max-height: 70vh !important;

  &.sticky-horizontal-first-col {

    table > tbody > tr > td:nth-child(2) {
      position: unset;
    }

    table > thead > tr > th:nth-child(2) {
      z-index: 1;
    }

    table > tbody > tr:not(.report-warning) > td:nth-child(1) {
      background-color: white;
    }

    table > tbody > tr > td:nth-child(2) {
      background-color: transparent;
    }

    table > tbody > tr > td:nth-child(1),
    table > thead > tr > th:nth-child(1) {
      left: 0px;
    }
  }
}

.orders-r12-modal {
  max-width: 1300px;
}

.difference-table {
  max-width: unset;

  .budget-table__field-head {
    white-space: unset;
  }
}